import { render, staticRenderFns } from "./MgmtReports.vue?vue&type=template&id=13cda5ee&scoped=true&"
import script from "./MgmtReports.vue?vue&type=script&lang=js&"
export * from "./MgmtReports.vue?vue&type=script&lang=js&"
import style0 from "./MgmtReports.vue?vue&type=style&index=0&id=13cda5ee&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13cda5ee",
  null
  
)

export default component.exports